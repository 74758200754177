import styled from 'styled-components';
import { FormattedText } from 'utilities';

function Version(props) {
    return (

        <VersionText className="text-center">
          <p className="text-gray">
            <a href="https://taxrise.com/terms-of-use/?&landing_page=https%3A%2F%2Ftaxrise.com%2F">
              Terms of Use
            </a> |&nbsp;
            <a href="https://taxrise.com/privacy-policy/?&landing_page=https%3A%2F%2Ftaxrise.com%2F">
              Privacy Policy
            </a> |&nbsp;
            <a href="https://taxrise.com/california-privacy-notice/?&landing_page=https%3A%2F%2Ftaxrise.com%2F">
              California Privacy Notice
            </a> |&nbsp;
            <a href="https://taxrise.com/security/?&landing_page=https%3A%2F%2Ftaxrise.com%2F">
              Security
            </a>
          </p>
          © Copyright 2025 TaxRise™
        </VersionText>
    );
}

const VersionText = styled.p`
    //margin-bottom: 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.2em;
    margin-bottom: 100px;
`;

export default Version;
