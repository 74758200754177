import taxriseLogo from './taxrise.png';
import logo from './logo.png';
import hamburgerIcon from './hamburger-icon.png';
import signOutIcon from './signout.png';
import privacyIcon from './privacy.png';
import addressCardIcon from './address-card.png';
import amazonPay from './amazon-pay.png';
import arrowCircleRightIcon from './arrow-circle-right.png';
import arrowRightIcon from './arrow-right.png';
import ballotCheckIcon from './ballot-check.png';
import checkIcon from './check.png';
import chevronLeftIcon from './chevron-left.png';
import creditCard from './credit-card.png';
import creditIcon from './credit-icon.png';
import creditImage from './credit.png';
import dollarBag from './dollar-bag.png';
import downArrow from './down-arrow.png';
import fileContractIcon from './file-contract.png';
import fileInvocieDollar from './file-invoice-dollar.png';
import fileSignature from './file-signature.png';
import HouseIcon from './house.png';
import manualEntryIcon from './manual-entry-icon.png';
import plusCircleIcon from './plus-circle.png';
import paypalLogo from './paypal.png';
import plaidLogo from './plaid.png';
import userIcon from './user.png';
import visaLogo from './visa.png';
import caseManager from './case_manager.png';
import verifilinkIcon from './verfilink.png';
import contactIcon from './contact.png';
import documentIcon from './document.png';
import faqIcon from './faq.png';
import paymentIcon from './payment.png';
import summaryIcon from './summary.png';
import dashboardIcon from './dashboard.png';
import documentUpload from './document_upload.png';
import closeIcon from './close.png';
import warningIcon from './warning.png';
import successCheck from './success-check.png';
import purgoLogo from './purgo_logo_black.png';
import arrowRightBlue from './arrow-right-blue.png';
import calendarIcon from './calendar.png';
import mcLogo from './mc-logo.png';
import amexLogo from './amex-logo.png';
import discoverLogo from './discover-log.png';
import collapseExpandIcon from './collapse-expand.png';
import passShowIcon from './pass-show.png';
import passHideIcon from './pass-hide.png';
import downloadIcon from './download-icon.png';
import defaultIcon from './default.jpg';

// eslint-disable-next-line no-undef
const appId = process.env['REACT_APP_PROJECT_ID'] || 'default';

const img = {
    default: {
        logoImg: logo,
    },
    purgo: {
        logoImg: purgoLogo,
    },
};

const { logoImg } = img[appId];

export {
    logoImg,
    hamburgerIcon,
    signOutIcon,
    privacyIcon,
    addressCardIcon,
    amazonPay,
    arrowCircleRightIcon,
    arrowRightIcon,
    ballotCheckIcon,
    checkIcon,
    chevronLeftIcon,
    creditCard,
    creditIcon,
    creditImage,
    dollarBag,
    downArrow,
    fileContractIcon,
    fileInvocieDollar,
    fileSignature,
    HouseIcon,
    manualEntryIcon,
    paypalLogo,
    plaidLogo,
    plusCircleIcon,
    userIcon,
    visaLogo,
    caseManager,
    verifilinkIcon,
    contactIcon,
    documentIcon,
    faqIcon,
    paymentIcon,
    summaryIcon,
    dashboardIcon,
    documentUpload,
    closeIcon,
    warningIcon,
    successCheck,
    purgoLogo,
    arrowRightBlue,
    calendarIcon,
    mcLogo,
    amexLogo,
    discoverLogo,
    collapseExpandIcon,
    passShowIcon,
    passHideIcon,
    downloadIcon,
    defaultIcon
};
