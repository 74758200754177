import styled from 'styled-components';

function PageHeader({ children, ...props }) {
    return <Section {...props}> <div className="inner-header"> {children}</div></Section>;
}

const Section = styled.section`
    padding: 32px;
    margin: 0;
    margin-top: 100px;
    @media(min-width: 768px) {
        text-align: center;
        min-height: 199px;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .inner-header {
            max-width: 490px;
            margin: 0 auto;
        }    
    }
    
    h1,
    h2,

    p:last-child {
        margin-bottom: 0;
    }
    @media(min-width: 768px) {
        img {
            cursor: pointer;
            position: absolute;
            left: 20%;
            top: 32px;
            margin: 0;
        }
    }
`;

export default PageHeader;
