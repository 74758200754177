import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    body{
        font-family: 'Poppins', sans-serif;
        color: ${(props) => props.textColor}
    }

    h1{
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    }

    h2{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    p{
        line-height: 21px;
    }

    strong{
        font-weight: 600;
    }

    a{
        color: ${(props) => props.theme.primaryColor};

        :hover{
            color: ${({ theme }) => theme.linkHoverColor};
        }
    }

    .mx-auto{
        margin-left: auto;
        margin-right: auto;
    }

    // Ant customizations
    // Do it if there has no ant customization variable available.

    .ant-form-item-label > label{
        font-weight: 600;
    }

    .ant-input:focus, .ant-input-focused {
        box-shadow: none;
    }
    
    .ant-input {
        background-color: white;;
    }
    
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: none;
        border-right-width: 0px !important;
    }
    .ant-select {
        width: 100%;
    }

    .ant-select-dropdown{
        padding: 0;
        border-radius: 0;

        .ant-select-item{
            padding: 10px 16px;
            border-bottom: 1px solid #F0F1F2;
        }

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
            background-color: ${({ theme }) => theme.primaryColor};
            color: #fff;
            font-weight: 500;
        }
    }

    .ant-pagination-item:focus, .ant-pagination-item:hover, .ant-pagination-item-active{
        border-color: ${({ theme }) => theme.primaryColor};
    }

    .ant-pagination-item:focus a, .ant-pagination-item:hover a, .ant-pagination-item-active a{
        color: ${({ theme }) => theme.primaryColor};
    }

    .ant-pagination-next:focus .ant-pagination-item-link, 
    .ant-pagination-next:hover .ant-pagination-item-link, 
    .ant-pagination-prev:focus .ant-pagination-item-link, 
    .ant-pagination-prev:hover .ant-pagination-item-link{
        color: ${({ theme }) => theme.primaryColor};
        border-color: ${({ theme }) => theme.primaryColor};
    }
    
    .align-left {
        text-align: left;
    }
    
    .text-gray {
        color: lightgray;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-color: ${({ theme }) => theme.primaryColor};
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-right-width: 1px !important;
    }
    
    .border {
        margin: 16px 0;
        padding: 0;
        width: calc(100% + 64px);
        margin-left: -32px;
        border: 0 solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
    }
    .bottom-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        padding: 0 32px 17px 32px;    
        max-width: 490px;
        margin: auto;
    }
    .chart-y-label, .bar-text{
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: ${({ theme }) => theme.textColorDarkBlue}
    }
    .bar-text{
        color: #fff
    }
    .pb-40 {
        padding-bottom: 40px;
    }

    .pb-95 {
        padding-bottom: 95px;
    }

    .policy-text {
        font-size: 12px;
        line-height: 18px;
        color: #0F212E;
        a {
            text-decoration: underline;
        }
    }
    .mb-16{
        margin-bottom: 16px;
    }
    .mb-25{
        margin-bottom: 25px;
    }
    .mb-8{
        margin-bottom: 8px;
    }
    .mb-21{
        margin-bottom: 21px;
    }
    .mt-16 {
        margin-top: 16px;
    }
    .mt-17 {
        margin-top: 17px;
    }
    .mb-0{
        margin-bottom: 0 !important;
    }
    .mb-12{
        margin-bottom: 12px;
    }
    .mb-23{
        margin-bottom: 23px;
    }
    .mx-16{
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .mt-32{
        margin-top: 32px;
    }

    .text-center{
        text-align: center;
    }

    .text-bold{
        font-weight: 600;
    }

    .text-underline{
        text-decoration: underline;
    }

    .mb-20{
        margin-bottom: 20px;
    }

    .color-brand {
        color: ${({ theme }) => theme.primaryColor};
    }
    .color-gray {
        color: ${({ theme }) => theme.textColorGray}
    }
    .header-optimization {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #0F212E;
    }
    .documents-header {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0F212E;
    }
    .document-border {
        border: 0 solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
    }
    .mt-8{
        margin-top: 8px;
    }

    .r-ml-32{
        margin-left: -32px;
    }

    .r-mr-32{
        margin-right: -32px;
    }
    .pt-0{
        padding-top: 0 !important;
    }
    .py-32{
        padding-left: 32px;
        padding-right: 32px;
    }
    .font-700{
        font-weight: 700;
    }
    .mr-12{
        margin-right: 12px;
    }

    .pl-32{
        padding-left: 32px;
    }
    .ant-modal {
        top: 253px;
    }
    .br-0 {
        border: 0px solid transparent;
    }
    .mt-0 {
        margin-top: 0;
    }
    .scroll-y{
        overflow-y: auto;
    }
    .no-data{
        text-align: center;
        margin: 50px 10px;
        color: #757575;
    }
    .pd-0 {
        padding: 0 !important;
    }
    .pt-16 {
        padding-top: 16px;
    }
    .pb-16 {
        padding-bottom: 16px;
    }
    .full-width-radio .ant-radio-group {
        width: 100%;
    }
    .fix-head-img{
        &&{
            position: relative;
            left: auto;
            top: auto;
            cursor: auto;
        }
    }
    .simplebar-scrollbar::before {
        background-color: red;
    }
`;

export default GlobalStyles;
